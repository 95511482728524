<template>
  <div class="widgetContainer widgetContainer--scrollable full-width fund">
    <div class="widgetContainer__body">
      <div class="fund__move-out">
        <div class="listWithActionsv2">
          <ul>
            <li @click="showShiflInstantPay = true">
              <div class="details">
                <p class="label">
                  Shifl Instant Pay
                </p>
                <p class="value">
                  Transfer funds from your account to someone's account.
                </p>
              </div>
              <div class="action">
                <span class="icon-Arrow-small-right" />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Drawer class="default-drawer" title="Shifl Instant Pay" :components="shiflInstantPay" :show.sync="showShiflInstantPay" v-if="showShiflInstantPay" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { accountType } from '@m/contact/enums';
import { textCopy } from '@/utils/general';
import Drawer from '@/components/Drawer.vue';

export default {
  components: {
    Drawer
  },
  data() {
    return {
      showShiflInstantPay: false,
      shiflInstantPay:[
        {
          name: 'Search',
          default: true,
          component: () => import('@m/send/views/ShiflInstantPay/Search.vue')
        },
        {
          name: 'PersonAccounts',
          component: () => import('@m/send/views/ShiflInstantPay/PersonAccounts.vue')
        },
        {
          name: 'IntrabankTransfer',
          component: () => import('@m/send/views/ShiflInstantPay/IntrabankTransfer.vue')
        },
        {
          name: 'IntrabankFundOutSuccess',
          component: () => import('@m/send/views/IntrabankFundOutSuccess.vue')
        },
      ],
    };
  },
  computed: {
    ...mapGetters('account', ['getSelectedAccount']),
    ...mapGetters('contact', ['getSelectedContactData']),
    accountType() {
      return accountType[this.getSelectedAccount.type];
    }
  },
  methods: {
    ...mapActions('pay', ['intrabankPay']),
    ...mapActions('card', ['listAllCardByAccount']),
    ...mapActions('transaction', ['fetchRecentTransactions']),
    copyAction(textToBeCopied, type) {
      const that = this;
      textCopy(textToBeCopied, function (e) {
        if (e) {
          that.$apiErrorMessage(`Unable to copy ${type}`);
        } else {
          that.$notify({
            title: 'Success',
            message: `${type} copied`,
            type: 'success'
          });
        }
      });
    }
  }
};
</script>
<style lang="scss">
.fund {
  max-height: none;
  .fund__push-funds{
    background: var(--branding-primary);
  }
  &__container {
    border-radius: var(--radius);
    background-color: #fff;
    padding: 24px 16px 16px 16px;
    margin-bottom: 24px;

    .add {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      display: flex;
      transition: 0.2s;

      &:hover {
        border-color: var(--light-grey);
      }

      .icon {
        font-size: 48px;
        margin-right: 16px;
      }

      .header-2 {
        padding-bottom: 8px;
      }

      .fund-desc {
        color: var(--grey-1);
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &__header {
    font-size: 20px;
    font-weight: 600;
    line-height: 41px;
    padding: 6px 0;
  }

  &__details {
    .contact-info {
      margin-bottom: 0px;
      border-top: 1px solid #eeeeee;
      padding: 16px 0 14px 0;
      margin: 23px 0 0 0;

      li {
        position: relative;
      }

      .copy {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        cursor: pointer;
      }

      .value {
        text-overflow: ellipsis;
        max-width: 45%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
      }
    }
  }

  &__push-funds {
    background-color: var(--branding-primary);
    display: flex;
    min-height: 117px;
    border-radius: var(--radius);
    padding: 34px 64px;
    margin: 20px 0 32px 0;
    justify-content: space-between;

    @media screen and (max-width: 992px) {
      flex-direction: column;
      padding: 34px 34px;
    }

    @media screen and (max-width: 768px) {
      padding: 24px 17px;
      margin-top: 0;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    .main-title {
      padding-bottom: 12px;
    }

    .top-row {
      display: flex;
      align-items: center;
      position: relative;
    }

    p, span {
      color: #fff;
    }

    &__headers {
      display: flex;
      justify-content: center;

      p {
        font-size: 24px;
        display: flex;
        align-items: center;
      }

      span {
        padding-right: 7px;
        font-size: 14px;
        display: inline-block;
        max-width: 270px;
        line-height: 18px;
      }
    }

    &__numbers {
      position: relative;

      @media screen and (max-width: 992px) {
        margin-top: 24px;
      }

      .top-row {
        padding: 8px 100px 8px 0;

        @media screen and (max-width: 768px) {
          padding: 8px 0 8px 0;
        }
      }
      
      .icon-copy {
        cursor: pointer;
        position: absolute;
        top: 7px;
        right: 0;
        opacity: 0.6;
        transition: .3s;

        @media screen and (max-width: 992px) {
          position: static;
          margin-left: 20px;
        }

        &:hover {
          opacity: 1;
        }
      }

      p {
        font-size: 14px;
        min-width: 134px;
        font-weight: 600;
      }

      span {
        font-size: 14px;
      }
    }
  }

  .fund__move-in,
  .fund__move-out {
    width: 48%;
    flex-direction: column;
    display: inline-flex;

    @media screen and (max-width: 992px) {
      width: 100%;
    }

    .listWithActionsv2 {
      ul {
        li {
          .details {
            .label {
              font-size: 20px;
              padding-bottom: 6px;
            }
          }
        }
      }
    }
  }

  .fund__move-in {
    margin-right: 4%;
  }

  .purpose-input {
    outline: none;
    border: none;
    text-align: right;
    input {
      text-align: right;
    }
  }

  .back {
    cursor: pointer;
    display: flex;
    align-items: center;

    .icon-arrow-left {
      font-size: 18px;
    }

    p {
      color: #3c3c43;
      font-size: 17px;
      padding-left: 6px;
    }
  }
}
</style>
